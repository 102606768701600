import React, { useState, useEffect, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Theme from "../../themes/Theme";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { BasicStatusButton } from "../aggrid/dynamicAction";

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: Theme.tableStyle.tableHeaders.fontWeightImportant,
  },
}));

const BasicTable = ({
  dataTable,
  dataColumns,
  action,
  condition = [],
  align = "center",
  dinamicAction = [],
  rowsPerPageOpt = [5, 10, 25, 50],
  tableType = "",
  pageValue = 0,
  rowsPerPageValue = 10,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(pageValue);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageValue);
  const [t] = useTranslation("global");
  const [rowData, setRowData] = useState(dataTable);
  const [orderDirectionStore, setOrderDirectionStore] = useState("asc");
  const [orderDirectionDate, setOrderDirectionDate] = useState("asc");
  const [orderDirectionAmount, setOrderDirectionAmount] = useState("asc");
  const colOrder = ["amount", "store_name", "date"];

  useEffect(() => {
    setRowData(dataTable);
  }, [dataTable]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    if (tableType == "Micromerchant") {
      localStorage.setItem(tableType + "-page", parseInt(newPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (tableType == "Micromerchant") {
      localStorage.setItem(
        tableType + "-rowsPerPage",
        parseInt(event.target.value)
      );
    }
  };

  const getSort = (val1, val2) => {
    if (val1 < val2) return 1;
    if (val2 < val1) return -1;
    else return 0;
  };

  const sortArrayAmount = (arr, orderBy) => {
    switch (orderBy) {
      case "desc":
        return arr.sort((a, b) => {
          const amountA = parseFloat(a.amount.split("L")[1]);
          const amountB = parseFloat(b.amount.split("L")[1]);
          return getSort(amountA, amountB);
        });
      case "asc":
      default:
        return arr.sort((a, b) => {
          const amountA = parseFloat(a.amount.split("L")[1]);
          const amountB = parseFloat(b.amount.split("L")[1]);
          return getSort(amountB, amountA);
        });
    }
  };

  const sortArrayStore = (arr, orderBy) => {
    switch (orderBy) {
      case "desc":
        return arr.sort((a, b) => {
          const storeA = a.store;
          const storeB = b.store;
          return getSort(storeA, storeB);
        });
      case "asc":
      default:
        return arr.sort((a, b) => {
          const storeA = a.store;
          const storeB = b.store;
          return getSort(storeB, storeA);
        });
    }
  };

  const sortArrayDate = (arr, orderBy) => {
    switch (orderBy) {
      case "desc":
        return arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return getSort(dateA, dateB);
        });
      case "asc":
      default:
        return arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return getSort(dateB, dateA);
        });
    }
  };

  const handleSortRequest = (col) => {
    if (col.includes("amount")) {
      setRowData(sortArrayAmount(rowData, orderDirectionAmount));
      setOrderDirectionAmount(orderDirectionAmount === "asc" ? "desc" : "asc");
    } else if (col.includes("store_name")) {
      setRowData(sortArrayStore(rowData, orderDirectionStore));
      setOrderDirectionStore(orderDirectionStore === "asc" ? "desc" : "asc");
    } else if (col.includes("date")) {
      setRowData(sortArrayDate(rowData, orderDirectionDate));
      setOrderDirectionDate(orderDirectionDate === "asc" ? "desc" : "asc");
    }
  };

  if (Object.keys(dataTable).length === 0)
    return (
      <div className="no-information-message">{t("Users.Delete.NoUsers")}</div>
    );

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const getOrder = (col) => {
    if (col.includes("amount")) return orderDirectionAmount;

    if (col.includes("store_name")) return orderDirectionStore;
    else return orderDirectionDate;
  };

  const getTableCell = (column, idx) => {
    return (
      <TableCell key={idx} align={align} className={classes.header}>
        {colOrder.includes(column.split(".").pop()) ? (
          <TableSortLabel
            active={true}
            direction={getOrder(column)}
            onClick={() => handleSortRequest(column)}
          >
            {t(column)}
          </TableSortLabel>
        ) : (
          t(column)
        )}
      </TableCell>
    );
  };

  const valueDesign = (result, cellData) => {
    if (result.icon === "MicromerchantsFormStatus") {
      return (
        <BasicStatusButton
          title="Micromerchants.LevelChange.LblStatusForm"
          color="green"
          icon={<CheckIcon />}
        />
      );
    } else {
      if (cellData) {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Valid"
            color="green"
            icon={<CheckIcon />}
          />
        );
      } else {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Pending"
            color="yellow"
            icon={<AccessTimeIcon />}
          />
        );
      }
    }
  };

  const cellValue = (rowData, cell, existDinamicAction) =>
    existDinamicAction
      ? valueDesign(dinamicAction.filter((x) => x.field === cell)[0], rowData)
      : rowData;

  return (
    <div>
      <div>
        <Paper
          sx={{
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            borderRadius: "16px",
          }}
        >
          <TableContainer role="grid">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {dataColumns.map((col, idx) =>
                    col.includes("Action") ? (
                      <TableCell
                        key={idx}
                        align="center"
                        colSpan="2"
                        className={classes.header}
                      >
                        {t(col)}
                      </TableCell>
                    ) : (
                      getTableCell(col, idx)
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <StyledTableRow key={uuidv4()}>
                        {Object.keys(row).map((cell, index) => {
                          if (index + 1 === Object.keys(row).length) {
                            if (condition.includes(cell)) {
                              return action !== undefined && action(row, i);
                            } else {
                              return (
                                <Fragment key={uuidv4()}>
                                  <TableCell
                                    key={uuidv4()}
                                    align={align}
                                    style={Theme.tableStyle.tableBody}
                                  >
                                    {row[cell]}
                                  </TableCell>
                                  {action !== undefined && action(row, i)}
                                </Fragment>
                              );
                            }
                          } else {
                            if (!condition.includes(cell)) {
                              return (
                                <TableCell
                                  key={uuidv4()}
                                  align={align}
                                  style={Theme.tableStyle.tableBody}
                                >
                                  {cellValue(
                                    row[cell],
                                    cell,
                                    dinamicAction.some(
                                      (item) => item.field === cell
                                    )
                                  )}
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOpt}
            component="div"
            count={dataTable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Insights.Transactions.Table.LabelRowsPerPage")}
          />
        </Paper>
      </div>
    </div>
  );
};

export default BasicTable;
