import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_hn from "./translations/hn/global.json";
import global_bo from "./translations/bo/global.json";
import global_py from "./translations/py/global.json";
import global_sv from "./translations/sv/global.json";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./themes/Theme";
import { hotjar } from "react-hotjar";
import countries from "./translations/country.json";

const result = window.location.origin;
const div = result.split(".");
let country = "es";
for (const x in div) {
  for (const y in Object.keys(countries)) {
    if (div[x] === Object.keys(countries)[y]) {
      country = div[x];
    }
  }
}

i18next.init({
  lng: country,
  resources: {
    hn: {
      global: global_hn,
    },
    py: {
      global: global_py,
    },
    bo: {
      global: global_bo,
    },
    sv: {
      global: global_sv,
    },
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

hotjar.initialize(
  `${process.env.REACT_APP_HJID}`,
  `${process.env.REACT_APP_HJSV}`
);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={Theme}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
