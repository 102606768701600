import React, { useState, forwardRef, useImperativeHandle } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Createcron from "../../commons/createcron";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default forwardRef((props, ref) => {
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function inputHandler(e) {
    setSelectedValue(e.target.value);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedValue;
      },
    };
  });

  return (
    <>
      <input
        data-testid="cron-value"
        type="text"
        className="ag-text-field-input"
        onChange={inputHandler}
        readOnly={true}
        value={selectedValue}
        placeholder={"Enter " + props.column.colId}
      />
      <SettingsIcon onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <div className="my-vertical-cron">
              <Createcron
                setValue={setSelectedValue}
                value={selectedValue}
              ></Createcron>
            </div>
          </Grid>
        </Box>
      </Modal>
    </>
  );
});
