import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Paper,
  Autocomplete,
  TextField,
  InputBase,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";

export default function Search({
  backUp,
  setSearch,
  columnsSearch,
  setIsSearch,
}) {
  const [t] = useTranslation("global");

  const handleSubmit = (event) => {
    event.preventDefault();
    SearchValue(event.target[0].value.toLowerCase());
  };

  const handleChange = (event) => {
    event.preventDefault();
    SearchValue(event.target.value.toLowerCase());
  };

  function SearchValue(value) {
    if (value !== "") {
      setIsSearch(true);
      setSearch(getRecords(value));
      setIsSearch(false);
    } else {
      setSearch(backUp);
      setIsSearch(false);
    }
  }

  const getRecords = (value) => {
    return backUp.filter((row) => {
      for (let col of Object.values(columnsSearch)) {
        if (
          row[col] !== undefined &&
          row[col] !== null &&
          row[col].replace(",", "").toLowerCase().includes(value)
        )
          return true;
      }
    });
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        height: "4.2vh",
        display: "flex",
        alignItems: "center",
        width: 450,
        boxShadow: "none",
        border: "1px solid #BFC7D0",
        boxSizing: "border-box",
        borderRadius: "8px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t("Buttons.SearchInsight")}
        inputProps={{ "aria-label": "search", "data-testid": "input-search" }}
        onChange={handleChange}
      />
      <IconButton
        type="submit"
        sx={{ p: "10px", color: "#343C46" }}
        aria-label="search"
        data-testid="button-search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

const SearchBack = ({ setSearch, endPoint }) => {
  const [t] = useTranslation("global");

  const [options, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState("hidden");
  const axiosPrivate = useAxiosPrivate();

  const optionLabel = (option) => {
    let value = option;

    if (option.topicId !== undefined && option.topicId !== null)
      value = option.search;

    return value;
  };

  const filterOptions = (option) => {
    return option;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (value.length > 2) {
      setIsSearching("visible");

      axiosPrivate
        .get(endPoint, { params: { search: value } })
        .then((response) => {
          setIsSearching("hidden");
          setOptions(response.data);
        })
        .catch(() => {
          setIsSearching("hidden");
          setOptions([]);
        });
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();

    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value.topicId !== undefined &&
      value.topicId !== null
    )
      setSearch(value);
  };

  return (
    <Autocomplete
      data-testid="autocomplete"
      sx={{
        width: "120vh",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
      }}
      getOptionLabel={optionLabel}
      options={options}
      renderOption={(props, option) => {
        return (
          <span {...props} key={uuidv4()}>
            {option.search}
          </span>
        );
      }}
      freeSolo
      filterOptions={filterOptions}
      onChange={handleChange}
      popupIcon={<SearchIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            borderRadius: "8px",
          }}
          size="small"
          label={t("Buttons.Search")}
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                <InputAdornment position="start">
                  <CircularProgress
                    style={{
                      width: "30px",
                      height: "30px",
                      visibility: isSearching,
                    }}
                  />
                </InputAdornment>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { SearchBack };
