import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import AggridTable from "../aggrid/aggridTable";
import { DynamicAction } from "../aggrid/dynamicAction";

const Owners = () => {
  const [t] = useTranslation("global");

  const url = "owners";
  let params;
  const columns = [
    {
      headerName: t("Owners.Merchant"),
      field: "name",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.MerchantId"),
      field: "wallet_id",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.Status.Status"),
      field: "is_activate",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "IsDisabled" },
      flex: 1,
      headerClass: "ag-header-center",
    },
    {
      headerName: t("Owners.Detail"),
      cellRendererFramework: DynamicAction,
      cellRendererParams: { path: "/Merchants/", icon: "LinkDetail" },
      flex: 1,
      headerClass: "ag-header-center",
    },
  ];

  const ScreenOwners = () => {
    return (
      <Fragment>
        <AggridTable
          columnDefs={columns}
          pagination={true}
          url={url}
          params={params}
          upperCase={false}
          height={400}
          width={"100%"}
        />
      </Fragment>
    );
  };

  return <div className="App">{ScreenOwners()}</div>;
};

export default Owners;
