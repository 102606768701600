import React from "react";
import Sesion from "../components/commons/sesion";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../themes/Theme";
import Integratorconfig from "../components/configuration/integratorconfig";

const IntegratorConfiguration = () => {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"IntegratorConfiguration"} />
      <ThemeProvider theme={Theme}>
        <Integratorconfig />
      </ThemeProvider>
    </div>
  );
};

export default IntegratorConfiguration;
