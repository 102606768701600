import React from "react";
import Button from "@mui/material/Button";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../themes/Theme";
import { Alert, Confirm } from "../components/utils/alert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

export const SendEmail = ({ fileData, fileName }) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  function postTransfer(dataFile, name) {
    let param = {
      ...dataFile,
      name,
      receiver: auth.username,
    };
    axiosPrivate
      .post("transaction/reports/emails", {
        body: param,
        jobNumber: param.client_id,
      })
      .then((response) => {
        if (response.status === 200) {
          return Alert(t("Transfer.SuccessEmailText"), "success");
        } else {
          return Confirm("Error!", t("Transfer.Error"), "success");
        }
      })
      .catch(() => {
        return Alert(t("Transfer.Error"), "error");
      });
  }

  return (
    <ThemeProvider theme={Theme}>
      <Button
        variant="outlined"
        startIcon={<EmailOutlinedIcon />}
        onClick={() => postTransfer(fileData, fileName)}
        className="action-button-outlined-secondary"
      >
        {t("Buttons.Email")}
      </Button>
    </ThemeProvider>
  );
};
