import { currencyFormatter } from "../../utils/validateExpression";

export function DataTableFormat(
  response,
  setColumnsSearch,
  setDataBackUp,
  setColumns,
  setData,
  setDatesFilter
) {
  let dateInt;
  let dateEnd;

  const setDates = (date) => {
    if (!dateInt && !dateEnd) {
      dateInt = new Date(date);
      dateEnd = new Date(date);
    }
    let dates = [];
    dates.push(dateInt);
    dates.push(new Date(date));
    dateInt = new Date(Math.min.apply(null, dates));
    dates[0] = dateEnd;
    dateEnd = new Date(Math.max.apply(null, dates));
  };

  const concactValues = (columns, trans) => {
    let col = [];

    for (let value of Object.values(columns)) {
      col.push(trans.concat(value));
    }
    return col;
  };

  if (response.data !== "") {
    const trans = "Insights.Transactions.Table.";
    let col = concactValues(response.data.columns, trans);

    for (let values of Object.values(response.data.results)) {
      values.amount = currencyFormatter(values.amount || 0, "es-HN", "HNL");
      setDates(values.date);
    }
    setDatesFilter({
      dateInit: dateInt,
      dateEnd: dateEnd,
    });
    setColumns(col);
    setData(response.data.results);
    setDataBackUp(response.data.results);
    setColumnsSearch(response.data.search);
  }
}
