import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import EditParams from "./EditParams";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const throwError = () => {
  throw Error("Value error");
};

const string_to_array_object = (values) => {
  let current = values;
  let array = [{}];
  try {
    !current && throwError();
    current = current.replace(/'/g, '"');
    current = JSON.parse(`{${current}}`);
    array = [];
    for (const property in current) {
      array.push(`{"${property}": "${current[property]}"}`);
    }
    return array;
  } catch (error) {
    return array;
  }
};

const string_to_object = (values) => {
  let current = values;

  try {
    !current && throwError();
    current = current.replace(/'/g, '"');
    return JSON.parse(`{${current}}`);
  } catch (error) {
    return [];
  }
};

export default forwardRef((props, ref) => {
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [components, setComponents] = useState(
    string_to_array_object(props.value)
  );
  const [activeButton, setactiveButton] = useState(true);
  const [valuejson, setvaluejson] = useState(string_to_object(props.value));
  const [t] = useTranslation("global");

  function addComponent() {
    setComponents([...components, '{"": "{requestId}"}']);
    setactiveButton(true);
  }

  function inputHandler(e) {
    setSelectedValue(e.target.value);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedValue;
      },
    };
  });

  useEffect(() => {
    if (valuejson.length !== 0) {
      setactiveButton(false);
    }
  }, []);

  const set_params_value = (data) => {
    let paramstring = obj_to_string(JSON.stringify(data));
    setactiveButton(false);
    setvaluejson(data);
    setComponents(string_to_array_object(paramstring));
    setSelectedValue(paramstring);
  };

  const obj_to_string = (value) => {
    let newWord = value.slice(1);
    newWord = newWord.slice(0, -1);
    return newWord;
  };

  const obj_to_key_array = (obj) => {
    return Object.keys(obj);
  };

  function processIndex(i, new_o, oldkey, newkey, json) {
    if (i === oldkey) new_o[newkey] = json[oldkey];
    else new_o[i] = json[i];

    return new_o;
  }

  const update_key = (newkey, index) => {
    let json = { ...valuejson };
    let keys = obj_to_key_array(json);
    let oldkey = keys[index];

    if (oldkey !== undefined) {
      let new_o = {};
      for (let i in json) {
        new_o = processIndex(i, new_o, oldkey, newkey, json);
      }
      json = new_o;
    } else {
      setactiveButton(false);
    }
    return json;
  };

  const getvalue = (key, value, index) => {
    let data = update_key(key, index);
    data[key] = value;
    let keys = obj_to_key_array(data);
    keys.splice(index, 1);
    if (keys.includes(key) === false) {
      set_params_value(data);
    }
  };

  const deleteItem = (item) => {
    const data = { ...valuejson };
    delete data[item];
    set_params_value(data);
  };

  return (
    <>
      <input
        data-testid="param-value"
        type="text"
        className="ag-text-field-input"
        onChange={inputHandler}
        readOnly={true}
        value={selectedValue}
        placeholder={"Enter " + props.column.colId}
      />
      <SettingsIcon onClick={handleOpen} data-testid="config" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container style={{ marginBottom: "10%" }}>
            <Grid item xs={6}></Grid>
            <Grid container style={{ justifyContent: "end" }} item xs={6}>
              <Button
                variant="outlined"
                style={
                  activeButton === false
                    ? {
                        margin: "10px",
                        height: "auto",
                        borderRadius: "10px",
                        borderColor: "#26438A",
                        textTransform: "none",
                        backgroundColor: "white",
                        color: "#26438A",
                      }
                    : {
                        margin: "10px",
                        height: "auto",
                        borderColor: "white",
                      }
                }
                size="small"
                onClick={addComponent}
                startIcon={<AddIcon />}
                disabled={activeButton}
              >
                {t("AGgrid.Popup.AddParam")}
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: "5%" }}>
            <TextField
              id="outlined-uncontrolled"
              label={t("AGgrid.Popup.CurrentValue")}
              value={props.value}
              size="small"
              disabled
              fullWidth
            />
          </Grid>
          <Grid container style={{ marginBottom: "10%" }}>
            <TextField
              id="outlined-uncontrolled"
              label={t("AGgrid.Popup.NewValue")}
              value={obj_to_string(JSON.stringify(valuejson))}
              size="small"
              disabled
              fullWidth
            />
          </Grid>
          {components.map((data, index) => (
            <EditParams
              key={index}
              getvalue={getvalue}
              index={index}
              data={data}
              deleteItem={deleteItem}
            />
          ))}
        </Box>
      </Modal>
    </>
  );
});
