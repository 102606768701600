import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../themes/Theme";
import Transfer from "./transfers";

export default function Insights() {
  const [t] = useTranslation("global");

  return (
    <ThemeProvider theme={Theme}>
      <p style={{ fontFamily: "Roboto", fontWeight: "600", fontSize: "16px" }}>
        {t("Insights.Message.Information")}
      </p>
      <Transfer />
    </ThemeProvider>
  );
}
