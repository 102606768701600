import React from "react";
import Insights from "../components/insights/insights";
import Sesion from "../components/commons/sesion";

export default function Insight() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Insights"} />
      <Insights />
    </div>
  );
}
