export function validateexp(currentvalue, exp, value, set, description) {
  if (exp) {
    if (exp.test(currentvalue)) {
      set({
        ...value,
        value: currentvalue,
        error: false,
        errordescription: "",
      });
      return true;
    } else {
      set({ ...value, error: true, errordescription: description });
    }
  }
}

export function formatYMD(date) {
  if (date) {
    return date.toISOString().slice(0, 10);
  } else {
    return "";
  }
}

export function currencyFormatter(number, local, currency) {
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency: currency,
  }).format(number);
}

export function monthAdd(date, month) {
  let temp = new Date(date.getFullYear(), date.getMonth(), 1);

  temp.setMonth(temp.getMonth() + month + 1);
  temp.setDate(temp.getDate() - 1);

  if (date.getDate() < temp.getDate()) temp.setDate(date.getDate());

  return temp;
}
