import React, { Fragment, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  Avatar,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import TabPanel from "../../utils/tabPanel";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MicromerchantDetail = () => {
  const [t] = useTranslation("global");
  const { state } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    localStorage.setItem("MicromerchantDetail", "1");
  }, []);

  const styles = {
    card: {
      borderRadius: "10px",
    },
    cardContent: {
      textAlign: "left",
      paddingBottom: "3px !important",
      paddingTop: "3px !important",
      width: "100%",
    },
    avatar: {
      bgcolor: "#DADADA",
      width: 55,
      height: 55,
      marginTop: "-10px",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "700",
      marginTop: "12px",
      lineHeight: "18px",
    },
    subtitle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "6px",
      lineHeight: "18px",
    },
    tab: {
      minWidth: "172px",
      fontFamily: "Roboto",
      fontSize: "18px",
      color: "#404A56",
      opacity: 100,
      fontWeight: 500,
    },
    header: {
      ...theme.typography.body1,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#404A56",
      boxShadow: "none",
      fontWeight: "bold",
    },
    tag: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#708195",
      boxShadow: "none",
    },
    tagContent: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#404A56",
      fontWeight: "bold",
      boxShadow: "none",
    },
  };

  const getDate = (application_date) => {
    let date = application_date.split("-");
    let newDate = date[2] + "/" + date[1] + "/" + date[0];
    return newDate;
  };

  const renderMicromerchant = () => {
    return (
      <Card style={{ borderRadius: "20px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            sx={{ borderStyle: "none" }}
            className={"card-grid-padding"}
          >
            <Grid
              item
              xs={6}
              spacing={2}
              sx={{ borderStyle: "none" }}
              style={{ display: "flex", flexWrap: "wrap", height: "0px" }}
            >
              <Grid item xs={12} sx={{ borderStyle: "none" }}>
                <div style={styles.header}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Title"
                  )}
                </div>
              </Grid>
              <Grid item xs={3}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Merchant"
                  )}
                </span>
              </Grid>
              <Grid item xs={9}>
                <span style={styles.tagContent}>{state.public_name}</span>
              </Grid>

              <Grid item xs={3}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Id"
                  )}
                </span>
              </Grid>
              <Grid item xs={9}>
                <span style={styles.tagContent}>{state.client_id}</span>
              </Grid>

              <Grid item xs={3}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Email"
                  )}
                </span>
              </Grid>
              <Grid item xs={9}>
                <span style={styles.tagContent}>{state.email}</span>
              </Grid>

              <Grid item xs={3}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Level"
                  )}
                </span>
              </Grid>
              <Grid item xs={9}>
                <span style={styles.tagContent}>{state.level}</span>
              </Grid>

              <Grid item xs={3}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.RegistrationDate"
                  )}
                </span>
              </Grid>
              <Grid item xs={9}>
                <span style={styles.tagContent}>
                  {getDate(state.application_date)}
                </span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{ borderStyle: "none" }}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Grid item xs={12} sx={{ borderStyle: "none" }}>
                <div style={styles.header}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Title"
                  )}
                </div>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.PublicName"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>{state.public_name}</span>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Category"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>{state.business_category}</span>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.BusinessDescription"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>
                  {state.business_description}
                </span>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.StateAndCity"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>
                  {state.state} {state.city}
                </span>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Address"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>
                  {state.street_and_number == ""
                    ? ""
                    : state.street_and_number + " C.P " + state.post_code}
                </span>
              </Grid>

              <Grid item xs={4}>
                <span style={styles.tag}>
                  {t(
                    "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.SocialNetworks"
                  )}
                </span>
              </Grid>
              <Grid item xs={8}>
                <span style={styles.tagContent}>
                  {Object.keys(state.social).map(
                    (key, i) => key + ": " + state.social[key] + " "
                  )}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <Fragment>
      <Card style={styles.card}>
        <Grid
          container
          direction="row"
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item md={6} xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Box display="flex" justifyContent="left" alignItems="left">
                  <CardContent sx={styles.cardContent}>
                    <Grid container>
                      <Grid item md={1}>
                        <Typography variant="h3" style={{ marginTop: "13px" }}>
                          <Avatar alt="Remy Sharp" sx={styles.avatar}>
                            <PersonIcon className="Cursor" fontSize="40" />
                          </Avatar>
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        md={10}
                        sx={{ paddingLeft: "10px", marginLeft: "10px" }}
                      >
                        <Typography variant="subtitle1" style={styles.title}>
                          {state.public_name}
                        </Typography>
                        <Typography variant="subtitle1" style={styles.subtitle}>
                          Micromerchant
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Fragment>
        <Box
          sx={{ borderBottom: 1, borderColor: "#DFE3E7" }}
          mt={3}
          mr={3}
          ml={0}
        >
          <Tabs value={0}>
            <Tab
              label={t("Micromerchants.MicromerchantDetail.TabPerfil.Tab")}
              style={styles.tab}
            />
          </Tabs>
        </Box>
        <TabPanel value={0} index={0}>
          <div style={{ paddingTop: "30px" }}>{renderMicromerchant()}</div>
        </TabPanel>
      </Fragment>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "16px",
        }}
      >
        <Link
          data-testid="custom-element"
          to={`/Micromerchants`}
          style={{
            color: "#707070",
            fontSize: "16px",
            background: "#FFF",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #f8c327",
          }}
        >
          <ArrowBackIcon />
          <div style={{ display: "inline-block", marginLeft: "10px" }}>
            {t("Micromerchants.MicromerchantDetail.BtnReturn")}
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default MicromerchantDetail;
