import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import ContentEditable from "react-contenteditable";
import { useTranslation } from "react-i18next";
import GenericBackdrop from "../../../utils/GenericBackdrop";
import { Alert } from "../../utils/alert";
import { axiosClient } from "../../commons/axiosClient";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import pictureIcon from "../../../img/pictureIcon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Icon from "./icon";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AddHelp = ({ setIsAddHelp, idTopic, setIdTopic }) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const [inputTopic, setInputTopic] = useState("");
  const [inputsQuestions, setInputsQuestions] = useState([
    { question: "", answer: "" },
  ]); //si se pone la variable valuesHelp genera un bug pero, deben ser los mismo valores
  const [iconData, setIcons] = useState([]);
  const { auth } = useAuth();

  const [inputTopicDescription, setInputTopicDescription] = useState("");
  const [inputTopicIconId, setInputTopicIconId] = useState("");
  const [inputTopicIconPath, setInputTopicIconPath] = useState("");
  const [removeQuestions, setRemoveQuestions] = useState([]);

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const nameInputQuestion = "question"; //same in the json valuesHelp
  const nameInputAnswer = "answer"; //same in the json valuesHelp
  const idContentEdit = "contentEdit-";
  const idAddImage = "addImage-";
  const nameInputTopic = "topic";
  const valuesHelp = { question: "", answer: "" };

  const country_id = auth.country_id;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openImage, setOpenImage] = useState(false);
  const handleOpen = () => setOpenImage(true);
  const handleClose = () => setOpenImage(false);
  const [urlImage, setUrlImage] = useState("");

  useEffect(() => {
    if (idTopic !== undefined && idTopic !== null) {
      axiosClient
        .get(`help/faqs/${idTopic}`)
        .then((response) => {
          setInputTopicIconId(response.data.topic.iconId);
          setInputTopicIconPath(response.data.topic.path);
          setInputTopicDescription(response.data.topic.description);
          setInputTopic(response.data.topic.name);
          setInputsQuestions(response.data.questions);
        })
        .catch((_err) => {
          Alert("", t("General.Message.ErrorService"), "");
        });
    }
    getIcons();
  }, []);

  const handleInputChange = (event, index) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (index !== undefined && index !== null) {
      const items = [...inputsQuestions];
      items[index][name] = value;

      setInputsQuestions(items);
    } else {
      if (name === nameInputTopic) setInputTopic(value);
      // else if (name === nameInputTopicDescription)
      //   setInputTopicDescription(value);
    }
  };

  const handleEditChange = (event, index) => {
    const { value } = event.target;
    const items = [...inputsQuestions];
    items[index][nameInputAnswer] = value;

    setInputsQuestions(items);
  };

  const handleAddInputs = () => {
    setInputsQuestions([...inputsQuestions, valuesHelp]);
  };

  const handleRemoveInputs = (index) => {
    const items = [...inputsQuestions];

    setRemoveQuestions([...removeQuestions, ...items.splice(index, 1)]);
    setInputsQuestions(items);
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (!inputTopicIconId)
      return Alert("", t("Configuration.Help.Message.NoIcon"));
    if (isAnswerEmpty())
      return Alert("", t("Configuration.Help.Message.NoComplete"));

    if (idTopic !== undefined && idTopic !== null) updateQuestions();
    else saveQuestions();
  };

  const saveQuestions = () => {
    handleLoaderOpen();

    const body = {
      topic: inputTopic,
      description: inputTopicDescription,
      iconId: inputTopicIconId,
      countryId: country_id,
      questions: inputsQuestions,
    };

    axiosClient
      .post("help/faqs", body)
      .then(() => {
        handleLoaderClose();
        setIsAddHelp(false);
      })
      .catch((_) => {
        handleLoaderClose();
        return Alert("", t("General.Message.ErrorService"), "");
      });
  };

  const clearTopicSelected = () => {
    setRemoveQuestions([]);
    setInputTopicIconId(null);
    setInputTopicIconPath("");
    setInputTopicDescription("");
    setInputTopic("");
    setInputsQuestions([]);

    if (setIdTopic !== undefined) setIdTopic(null);
  };

  const handleDeleteTopic = () => {
    handleLoaderOpen();

    axiosClient
      .delete("help/topics", { params: { idTopic } })
      .then(() => {
        handleLoaderClose();
        clearTopicSelected();
      })
      .catch(() => {
        handleLoaderClose();
        return Alert("", t("General.Message.ErrorService"), "");
      });
  };

  const updateQuestions = () => {
    handleLoaderOpen();

    const body = {
      removeQuestions,
      id_topic: idTopic,
      topic: inputTopic,
      description: inputTopicDescription,
      icon: inputTopicIconId,
      countryId: country_id,
      questions: inputsQuestions,
    };

    axiosClient
      .patch("help/faqs", body)
      .then(() => {
        handleLoaderClose();
        clearTopicSelected();
      })
      .catch(() => {
        handleLoaderClose();
        return Alert("", t("General.Message.ErrorService"), "");
      });
  };

  const isAnswerEmpty = () => {
    let isEmpty = false;

    inputsQuestions.forEach((item) => {
      if (item.answer === "") isEmpty = true;
    });

    return isEmpty;
  };

  const EditIconButton = (props) => {
    return (
      <IconButton
        onMouseDown={(evt) => {
          evt.preventDefault(); // Avoids loosing focus from the editable area
          props.callback();
        }}
      >
        {props.icon}
      </IconButton>
    );
  };

  const editAddList = (type, inputId) => {
    if (document.activeElement.id !== inputId)
      document.getElementById(inputId).focus();

    let list = document.createElement("li");
    let element =
      type === "number"
        ? document.createElement("ol")
        : document.createElement("ul");

    innerHTMLelements([list, element]);
  };

  const editAddImage = async (event) => {
    event.preventDefault();

    document
      .getElementById(document.activeElement.id.replace(idAddImage, ""))
      .focus();

    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let imgScr = await saveImage(file);

      if (imgScr !== undefined && imgScr !== null) {
        let img = document.createElement("img");
        img.src = imgScr;
        img.alt = "img";
        img.className = "content-image";

        innerHTMLelements([img]);
      }
    }

    event.target.value = null;
  };

  const saveImage = async (file) => {
    handleLoaderOpen();

    let imgSrc;

    await axiosPrivate
      .post(`merchants/files`, {
        bucket: process.env.REACT_APP_BUCKET,
        folder: nameInputTopic,
        filename: `${uuidv4()}.${file.type.split("/")[1]}`,
      })
      .then(async (uploadResponse) => {
        const url = uploadResponse.data.url;
        let fields = uploadResponse.data.fields;
        let formData = new FormData();

        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });

        formData.append("file", file, file.name);

        let instance = axios.create({
          headers: {
            post: {
              "Content-Type": "multipart/form-data",
            },
          },
        });

        delete instance.defaults.headers.common["Authorization"];

        await instance
          .post(url, formData)
          .then(async () => {
            await axiosPrivate
              .get(`merchants/files`, {
                params: {
                  bucket: process.env.REACT_APP_BUCKET,
                  key: fields.key,
                  isView: true,
                  isDownload: false,
                  persist: true,
                },
              })
              .then((response) => {
                imgSrc = response.data;
              })
              .catch((err) => {
                console.log(err);
              });

            handleLoaderClose();
          })
          .catch((_) => {
            handleLoaderClose();
            return Alert("", t("General.Message.ErrorService"), "");
          });
      })
      .catch((_err) => {
        handleLoaderClose();
        return Alert("", t("General.Message.ErrorService"), "");
      });

    return imgSrc;
  };

  const innerHTMLelements = (items) => {
    try {
      const selection = window.getSelection && window.getSelection();

      if (selection && selection.rangeCount > 0) {
        const selectedRange = selection.getRangeAt(0);

        Object.values(items).forEach((item) => {
          selectedRange.surroundContents(item);
        });
      }
    } catch (_) {}
  };

  const getIcons = () => {
    const url = "/help/topics/icon";

    axiosClient
      .get(url)
      .then((response) => {
        setIcons(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageClick = (item) => {
    if (item.target.alt === "img") {
      handleOpen();
      setUrlImage(item.target.src);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        onSubmit={(event) => {
          handleSave(event);
        }}
        style={{
          width: 780,
          alignItems: "center",
        }}
      >
        <GenericBackdrop open={loader} />
        <Card
          className="mui-card"
          style={{
            height: 164,
            borderRadius: "16px !important",
            margin: "47px 0 32px 0",
          }}
        >
          <CardContent style={{ padding: 24 }}>
            <Typography
              gutterBottom
              component="div"
              variant="text18"
              style={{ marginBottom: 0 }}
            >
              {t("Configuration.Help.Category")}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              spacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Stack sx={{ width: "100%" }}>
                <Typography
                  gutterBottom
                  component="div"
                  variant="text14"
                  sx={{ margin: "10px 0 10px 0" }}
                >
                  {t("Configuration.Help.AddNameCategory")}
                </Typography>
                <TextField
                  required
                  label={t("Configuration.Help.Category")}
                  size="small"
                  fullWidth
                  name={nameInputTopic}
                  value={inputTopic}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    width: 328,
                    height: 40,
                    borderRadius: "8px !important",
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  setIconId={setInputTopicIconId}
                  setIconPath={inputTopicIconPath}
                  iconData={iconData}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        {inputsQuestions.map((item, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            style={{
              borderRadius: "16px !important",
              minHeight: 230,
              marginBottom: 32,
            }}
          >
            <Card className="mui-card" style={{ width: "100%" }}>
              <CardContent style={{ padding: 24 }}>
                <Typography
                  gutterBottom
                  component="div"
                  variant="text18"
                  style={{ marginBottom: 0 }}
                >
                  {t("Configuration.Help.QuestionContent")}
                </Typography>
                <TextField
                  required
                  label={t("Configuration.Help.Question")}
                  size="small"
                  sx={{
                    width: 732,
                    borderRadius: "8px !important",
                    marginTop: "10px",
                  }}
                  name={nameInputQuestion}
                  value={item.question}
                  onChange={(e) => handleInputChange(e, index)}
                />
                <Stack
                  direction="row"
                  style={{
                    border: "1px solid #DFE3E7",
                    borderRadius: "10px",
                    margin: "10px 0",
                    width: "fit-content",
                  }}
                >
                  <label
                    htmlFor="upload-image"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      accept="image/*"
                      id="upload-image"
                      data-testid="upload-image"
                      type="file"
                      style={{ display: "none" }}
                      onChange={editAddImage}
                    />
                    <IconButton
                      component="span"
                      id={`${idAddImage}${idContentEdit}${index}`}
                    >
                      <img src={pictureIcon} alt="pictureIcon" />
                    </IconButton>
                  </label>
                  <Divider orientation="vertical" flexItem />
                  <EditIconButton
                    icon={
                      <FormatListBulletedIcon style={{ color: "#000000" }} />
                    }
                    callback={() => {
                      editAddList("bullet", `${idContentEdit}${index}`);
                    }}
                  />
                  <EditIconButton
                    icon={
                      <FormatListNumberedIcon style={{ color: "#000000" }} />
                    }
                    callback={() => {
                      editAddList("number", `${idContentEdit}${index}`);
                    }}
                  />
                </Stack>
                <ContentEditable
                  onClick={imageClick}
                  id={`${idContentEdit}${index}`}
                  html={item.answer}
                  suppressContentEditableWarning={true}
                  disabled={false}
                  data-testid={t("Configuration.Help.Content")}
                  onChange={(e) => handleEditChange(e, index)}
                  style={{
                    width: 732,
                    minHeight: 40,
                    borderRadius: "8px !important",
                    marginTop: "10px",
                  }}
                />
              </CardContent>
            </Card>
            <Card
              style={{
                boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
                borderRadius: "10px",
                display: "flex",
                float: "right",
                height: "fit-content",
                marginRight: "-60px",
              }}
            >
              <CardActions
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {inputsQuestions.length - 1 === index && (
                  <Tooltip title={t("Configuration.Help.Actions.NewQuestion")}>
                    <IconButton
                      style={{ margin: "5px 0", padding: "0" }}
                      onClick={handleAddInputs}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {inputsQuestions.length !== index &&
                  inputsQuestions.length > 1 && (
                    <Tooltip
                      title={t("Configuration.Help.Actions.RemoveQuestion")}
                    >
                      <IconButton
                        style={{ margin: "5px 0", padding: "0" }}
                        data-testid={`delete-section-${index}`}
                        onClick={() => {
                          handleRemoveInputs(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
              </CardActions>
            </Card>
          </Stack>
        ))}
        <Card
          style={{
            border: "none",
            boxShadow: "none",
            marginLeft: "auto",
          }}
        >
          <CardActions sx={{ justifyContent: "end" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setIsAddHelp(false);
                clearTopicSelected();
              }}
            >
              {t("Configuration.Help.Actions.Cancel")}
            </Button>
            {idTopic ? (
              <Button
                variant="outlined"
                onClick={() => {
                  handleDeleteTopic();
                }}
              >
                {t("Configuration.Help.Actions.Delete")}
              </Button>
            ) : (
              <div />
            )}

            <Button variant="contained" type="submit">
              {t("Configuration.Help.Actions.Save")}
            </Button>
          </CardActions>
        </Card>

        <Dialog fullScreen={fullScreen} open={openImage} onClose={handleClose}>
          <DialogContent>
            <img
              data-testid="asd"
              src={urlImage}
              alt="asd"
              width="100%"
              height="100%"
            />
          </DialogContent>
        </Dialog>
      </form>
    </div>
  );
};

export default AddHelp;
