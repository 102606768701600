import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Confirm } from "../utils/alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QrCodeIcon from "@mui/icons-material/QrCode";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Tooltip from "@mui/material/Tooltip";
import MessageIcon from "@mui/icons-material/Message";
import { currencyFormatter } from "../utils/validateExpression";
import PermissionsGate from "../commons/PermissionsGate";

// Generic components
export function BasicStatusButton({
  title,
  icon,
  color,
  style = { fontSize: "12px" },
}) {
  const [t] = useTranslation("global");
  const _colors = {
    blue: {
      backgroundColor: "#E4F0FF",
      color: "#5B8FD2",
    },
    red: {
      backgroundColor: "#EFCCD2",
      color: "#B00020",
    },
    yellow: {
      backgroundColor: "#FFEEB5",
      color: "#7D6000",
    },
    green: {
      backgroundColor: "#DEF3DD",
      color: "#59C556",
    },
    gray: {
      backgroundColor: "#ADBAC0",
      color: "#737373",
    },
  };

  return (
    <Button
      disabled
      style={{
        ...{
          borderRadius: "15px",
          textTransform: "none",
          borderColor: "white",
          fontWeight: "bold",
          height: "32px",
          fontSize: "12px",
        },
        ..._colors[color],
        ...style,
      }}
      variant="outlined"
      startIcon={icon}
    >
      {t(title)}
    </Button>
  );
}

function BasicIconWithTooltip({ tooltip, icon, color, onClick, testId }) {
  const [t] = useTranslation("global");
  return (
    <span className={`ag-icon-${color}`}>
      <Tooltip title={t(tooltip)}>
        <IconButton onClick={onClick} data-testid={testId}>
          {icon}
        </IconButton>
      </Tooltip>
    </span>
  );
}

// Icons for owner details screen
function IconInProcess({ title, onClick, color = "yellow" }) {
  return (
    <BasicIconWithTooltip
      testId={"icon-cash-out-put-in-process"}
      onClick={onClick}
      color={color}
      tooltip={title}
      icon={<AccessTimeIcon className="Cursor" />}
    />
  );
}

function IconComplete({ title, onClick, color = "green" }) {
  return (
    <BasicIconWithTooltip
      testId={"icon-cash-out-complete"}
      onClick={onClick}
      color={color}
      tooltip={title}
      icon={<CheckCircleOutlineIcon className="Cursor" />}
    />
  );
}

function IconRejected({ title, onClick, color = "red" }) {
  return (
    <BasicIconWithTooltip
      testId={"icon-cash-out-reject"}
      onClick={onClick}
      color={color}
      tooltip={title}
      icon={<HighlightOffIcon className="Cursor" />}
    />
  );
}

function getPath(props, _path) {
  if (props.path !== undefined) return props.path;
  return _path;
}

function getStatusButton(status) {
  switch (status) {
    case "CREATED":
    case "created":
      return (
        <BasicStatusButton
          title="Operations.CashOut.Status.Requested"
          color="blue"
          icon={<MessageIcon />}
        />
      );
    case "PROCESS":
    case "process":
      return (
        <BasicStatusButton
          title="Operations.CashOut.Status.Pending"
          color="yellow"
          icon={<AccessTimeIcon />}
        />
      );
    case "REJECTED":
    case "rejected":
      return (
        <BasicStatusButton
          title="Operations.CashOut.Status.Rejected"
          color="red"
          icon={<CloseIcon />}
        />
      );
    case "COMPLETE":
    case "complete":
      return (
        <BasicStatusButton
          title="Operations.CashOut.Status.Complete"
          color="green"
          icon={<DoneIcon />}
        />
      );
    default:
      return <div>Icon</div>;
  }
}

function getTransfersChangeStatus(status, props, t) {
  const funcReject = async (e) => {
    if (
      await Confirm(
        "",
        `${t("Owners.Message.TransferInfo", {
          amount: currencyFormatter(props.data.amount),
          merchant: props.data.organization_name,
        })} ${t("Owners.Message.TransferRejected")} ${t(
          "Owners.Message.TransferQuestion"
        )}`,
        ""
      )
    )
      props.rejected(props, e);
  };
  const funcProcess = async (e) => {
    if (
      await Confirm(
        "",
        `${t("Owners.Message.TransferInfo", {
          amount: currencyFormatter(props.data.amount),
          merchant: props.data.organization_name,
        })} ${t("Owners.Message.TransferInProcess")} ${t(
          "Owners.Message.TransferQuestion"
        )}`,
        ""
      )
    )
      props.process(props, e);
  };
  const funcComplete = async (e) => {
    if (
      await Confirm(
        "",
        `${t("Owners.Message.TransferInfo", {
          amount: currencyFormatter(props.data.amount),
          merchant: props.data.organization_name,
        })} ${t("Owners.Message.TransferComplete")} ${t(
          "Owners.Message.TransferQuestion"
        )}`,
        ""
      )
    )
      props.complete(props, e);
  };

  switch (status) {
    case "created":
      return (
        <div>
          {
            <IconInProcess
              title="Operations.CashOut.Actions.Process"
              onClick={funcProcess}
            />
          }
          {
            <IconRejected
              title="Operations.CashOut.Actions.Rejected"
              onClick={funcReject}
            />
          }
        </div>
      );
    case "process":
      return (
        <div>
          {
            <IconComplete
              title="Operations.CashOut.Actions.Complete"
              onClick={funcComplete}
            />
          }
          {
            <IconRejected
              title="Operations.CashOut.Actions.Rejected"
              onClick={funcReject}
            />
          }
        </div>
      );
    default:
      return <div />;
  }
}

function getBankAccountStatus(status) {
  switch (status) {
    case "PENDING":
      return (
        <BasicStatusButton
          title="Operations.BankAccountValidations.Status.Pending"
          color="yellow"
          icon={<AccessTimeIcon />}
        />
      );
    case "REJECTED":
      return (
        <BasicStatusButton
          title="Operations.BankAccountValidations.Status.Rejected"
          color="red"
          icon={<CloseIcon />}
        />
      );
    case "ACCEPTED":
      return (
        <BasicStatusButton
          title="Operations.BankAccountValidations.Status.Valid"
          color="green"
          icon={<CheckIcon />}
        />
      );
    default:
      return <div>Icon</div>;
  }
}

export function DynamicAction(props) {
  const [t] = useTranslation("global");
  let path = "/";

  const DoneIconR = (
    <DoneIcon onClick={() => props.callback(props)} style={{ margin: "8px" }} />
  );
  const CheckCircleOutlineIconR = (
    <CheckCircleOutlineIcon
      onClick={() => props.callback(props)}
      style={{ margin: "8px" }}
    />
  );

  const IconDetail = (
    <VisibilityIcon
      className="Cursor"
      onClick={() => props.ViewDetail(props)}
      style={{ margin: "8px" }}
    />
  );
  const IconQR = (
    <QrCodeIcon
      className="Cursor"
      onClick={() => props.ViewQR(props)}
      style={{ margin: "8px" }}
    />
  );

  const IconValidateAccount = (
    <span className="ag-icon-green">
      <Tooltip title={t("Operations.BankAccountValidations.Actions.Validate")}>
        <IconButton
          data-testid="icon-accept-account"
          className="Cursor"
          onClick={(e) => {
            props.acceptAccount(props, e);
          }}
        >
          <CheckCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </span>
  );
  const IconRejectAccount = (
    <span className="ag-icon-red">
      <Tooltip title={t("Operations.BankAccountValidations.Actions.Reject")}>
        <IconButton
          data-testid="icon-reject-account"
          className="Cursor"
          onClick={(e) => {
            props.rejectAccount(props, e);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Tooltip>
    </span>
  );

  const IconProofBankAccount = (
    <IconButton
      data-testid="icon-show-image-account"
      className="Cursor"
      onClick={(e) => {
        props.showProofBankAccount(props, e);
      }}
    >
      <ReceiptLongIcon />
    </IconButton>
  );

  path = getPath(props, path);

  const LinkDetail = (
    <PermissionsGate scopes={"merchants.actions.view"}>
      <Link to={`${path}${props.data.client_id}`} className="ag-link-icon">
        <VisibilityIcon />
      </Link>
    </PermissionsGate>
  );

  const ButtonIsEnabled = (
    <BasicStatusButton
      title="Owners.Status.Enabled"
      color="green"
      icon={<DoneIcon />}
    />
  );
  const ButtonIsDisabled = (
    <BasicStatusButton
      title="Owners.Status.Disabled"
      color="gray"
      icon={<RemoveCircleOutlineIcon />}
    />
  );

  switch (props.icon) {
    case "DoneIcon":
      return DoneIconR;
    case "CheckCircleOutlineIcon":
      return CheckCircleOutlineIconR;
    case "Button":
      const status = props.data.status;
      return getStatusButton(status);
    case "StoresDetail":
      return (
        <div>
          <PermissionsGate scopes={"merchants.actions.viewQR"}>
            {IconQR}
          </PermissionsGate>
          <PermissionsGate scopes={"merchants.actions.viewBrancheDetail"}>
            {IconDetail}
          </PermissionsGate>
        </div>
      );
    case "TransfersChangeStatus":
      const statusIconTransfer = props.data.status;
      return getTransfersChangeStatus(statusIconTransfer, props, t);
    case "TransfersStatus":
      const statusTransfer = props.data.status;
      return getStatusButton(statusTransfer);
    case "LinkDetail":
      return LinkDetail;
    case "IsDisabled":
      const isActivate = props.data.is_activate;

      if (isActivate) return ButtonIsEnabled;

      return ButtonIsDisabled;
    case "IsDedeted":
      const isDedeted = props.data.is_deleted;

      if (isDedeted) return ButtonIsDisabled;

      return ButtonIsEnabled;
    case "BankAccountStatus":
      const statusBankAccount = props.data.status;
      return getBankAccountStatus(statusBankAccount);
    case "BankAccountActions":
      const bankAccountStatus = props.data.status;
      if (bankAccountStatus === "PENDING") {
        return (
          <div>
            <PermissionsGate scopes={"resources.configuration"}>
              {IconRejectAccount}
            </PermissionsGate>
            <PermissionsGate scopes={"resources.configuration"}>
              {IconValidateAccount}
            </PermissionsGate>
          </div>
        );
      }
      return <div />;
    case "ProofBankAccountView":
      return <div>{IconProofBankAccount}</div>;
    default:
      return <div>Icon</div>;
  }
}
