import React from "react";
import Operation from "../components/operations/operation";
import Sesion from "../components/commons/sesion";

export default function Operations() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Operations"} />
      <Operation />
    </div>
  );
}
