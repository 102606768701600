import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Typography, IconButton, Popover } from "@mui/material";

export default function Icon({ setIconId, setIconPath, iconData }) {
  const [t] = useTranslation("global");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [iconSelected, setIconSelected] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getId = (value) => {
    setIconId(value.id);
    setIconSelected(value.path);
    handleClose();
  };

  function selectIcon() {
    if (iconSelected)
      return (
        <SvgIcon>
          <path d={iconSelected} />
        </SvgIcon>
      );
    else if (setIconPath)
      return (
        <SvgIcon>
          <path d={setIconPath} />
        </SvgIcon>
      );
    else return <HelpOutlineIcon />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography gutterBottom variant="text14" mr={2}>
        {t("Configuration.Help.AddIcon")}
      </Typography>
      <IconButton
        data-testid="Añadir Ícono"
        onClick={handleClick}
        style={{
          width: "48px",
          height: "40px",
          border: "1px solid #DFE3E7",
          borderRadius: "10px",
          color: "#404A56",
        }}
      >
        {selectIcon()}
      </IconButton>
      <Popover
        PaperProps={{
          style: { borderRadius: 10, textAlign: "center" },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{
          left: 50,
          top: 10,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, width: "fit-content", padding: "10px" }}>
          <Grid
            container
            spacing={{ xs: 0, md: 0 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {Object.values(iconData).map((value, index) => (
              <Grid item xs={2} sm={3} md={3} key={index}>
                <IconButton
                  onClick={() => getId(value)}
                  style={{
                    color: "#708195",
                    width: "20px",
                    height: "20px",
                    margin: "5px 3px",
                  }}
                  data-testid="Icon Button"
                >
                  <SvgIcon viewBox="0 -2 18 26" style={{ fontSize: "24px" }}>
                    <path d={value.path} />
                  </SvgIcon>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </div>
  );
}
