import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Alert, Confirm, SnackBar } from "../utils/alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Chip from "@mui/material/Chip";
import AggridTable from "../aggrid/aggridTable";
import { DynamicAction } from "../aggrid/dynamicAction";
import TextField from "@mui/material/TextField";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonIcon from "@mui/icons-material/Person";
import { validEmail } from "../utils/validateExpression";
import CashOut from "../operations/cash_out/cash_out";
import PermissionsGate from "../commons/PermissionsGate";
import Avatar from "@mui/material/Avatar";
import QrGenerate from "../qr/qrGenerate";

const Title = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  boxShadow: "none",
  fontWeight: "bold",
}));

const Tag = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#708195",
  boxShadow: "none",
}));

const Content = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  fontWeight: "bold",
  boxShadow: "none",
}));

const OwnerDetail = ({ client_id }) => {
  const [t] = useTranslation("global");
  const [detailOwner, setDetailOwner] = useState({});
  const [urlImage, setUrlImage] = useState("");
  const [openImage, setOpenImage] = React.useState(false);
  const [value, setValue] = useState(0);
  const [render, setRender] = useState("Profile");
  const [screen, setScreen] = useState("stores");
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const handleOpen = () => setOpenImage(true);
  const handleClose = () => setOpenImage(false);
  const axiosPrivate = useAxiosPrivate();
  const arrTabs = [];

  const columnsStore = [
    {
      headerName: t("Owners.Merchant"),
      field: "store_name",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.MerchantId"),
      field: "store_key",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: "Actions",
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        ViewQR: ViewQR,
        ViewDetail: ViewDetail,
        icon: "StoresDetail",
      },
      flex: 1,
      cellClass: "ag-cell-right",
      headerClass: "ag-right-aligned-header",
    },
  ];

  function ViewQR(props) {
    setName(props.data.store_name);
    setKey(props.data.store_key);
    setMsisdn(props.data.msisdn);
    setScreen("qr");
  }
  function ViewDetail(props) {
    setScreen("detail");
  }

  const screenQr = () => {
    return (
      <Fragment>
        <QrGenerate
          url={"https://tigomoney.com/qr/pay"}
          name={`${name} | ${detailOwner.business_name}`}
          id_number={msisdn}
          origin="merchant-payment"
          amount={0}
          idSucursal={key}
          alg="HS256"
          account={msisdn}
          reason=""
        ></QrGenerate>
        {btnBack()}
      </Fragment>
    );
  };

  const screenDetail = () => {
    return (
      <Fragment>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            paddingBottom: "20px",
            fontWeight: 700,
            fontSize: "18px",
            color: "#404A56",
            textAlign: "initial",
          }}
        >
          {t("QrGenerate.DetailStore")}
        </Typography>
        <Grid style={{ paddingTop: "10px" }} container direction="column">
          <Grid style={{ paddingBottom: "50px" }} item xs={6}>
            <Card
              style={{
                textAlign: "initial",
                borderRadius: "16px",
                boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              }}
            >
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="div"
                      sx={{
                        paddingTop: "20px",
                        marginLeft: "24px",
                        fontWeight: 700,
                        fontSize: "24px",
                        color: "#404A56",
                      }}
                    >
                      {`${name} | ${detailOwner.business_name}`}
                    </Typography>
                    <Typography
                      variant="subTitle18_700"
                      gutterBottom
                      component="div"
                      sx={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        marginLeft: "24px",
                      }}
                    >
                      {key}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: "right",
                    paddingRight: "20px",
                    paddingTop: "35px",
                  }}
                ></Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {btnBack()}
      </Fragment>
    );
  };

  const btnBack = () => {
    return (
      <div
        style={{
          textAlign: "right",
          marginBlockEnd: "30px",
          marginTop: "30px",
        }}
      >
        <Button
          variant="outlined"
          style={{
            background: "white",
            color: "#404A56",
            textTransform: "none",
            minWidth: "139px",
            borderColor: "#FFC10E",
            height: "48px",
          }}
          onClick={() => setScreen("stores")}
          className="action-button-outlined"
        >
          {t("Buttons.Back")}
        </Button>
      </div>
    );
  };

  const statementUrl = (type) => {
    let p_bucket = "";
    let p_key = "";
    let view = "view";
    let download = "download";
    let isView = view === type;
    let isDownload = download === type;
    const url = "merchants/files";

    p_bucket = detailOwner.statement.bucket;
    p_key = detailOwner.statement.key;

    axiosPrivate
      .get(url, {
        params: {
          bucket: p_bucket,
          key: p_key,
          isView: isView,
          isDownload: isDownload,
        },
      })
      .then((response) => {
        setUrlImage(response.data);
        if (isView) {
          handleOpen();
        } else {
          window.open(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function updateStatusAccount(status) {
    let urlAccount = `withdrawals/bank-accounts/${detailOwner.account_id}/status`;

    axiosPrivate
      .patch(urlAccount, JSON.stringify({ status: status }))
      .then((_) => {
        setDetailOwner({ ...detailOwner, status: status });
        SnackBar(t("Owners.Message.AccountStatus"), "success");
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  }

  const [ownerDisabled, setOwnerDisabled] = useState(false);
  const [switchActive, setSwitchActive] = useState(false);

  const handleChangeDisable = (event) => {
    setOwnerDisabled(event.target.checked);
    setSwitchActive(event.target.checked);
    handleUpdateOwner(true, false);
  };

  const handleUpdateOwner = (status = false, isEmail = false) => {
    let urlOwner = `owners/${client_id}`;
    let paramsOwner = {};
    if (status) paramsOwner = { status: !ownerDisabled };
    else if (isEmail) {
      if (!validEmail.test(document.getElementById("owner-email").value))
        return SnackBar(t("Owners.Message.InvalidEmail"), "warning");

      paramsOwner = { email: document.getElementById("owner-email").value };
    }

    axiosPrivate
      .patch(urlOwner, JSON.stringify(paramsOwner))
      .then((_) => {
        if (isEmail) {
          setDetailOwner({
            ...detailOwner,
            email: document.getElementById("owner-email").value,
          });
          setEditEmail(false);
        }

        SnackBar(
          status
            ? t("Owners.Message.OwnerStatus")
            : t("Owners.Message.UpdateEmail"),
          "success"
        );
      })
      .catch((_) => {
        setSwitchActive(switchActive);
        setOwnerDisabled(ownerDisabled);

        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  const [showDetail, setShowDetail] = useState(false);

  const getDetailMerchant = () => {
    let url = `owners/${client_id}/details`;
    axiosPrivate
      .get(url)
      .then((response) => {
        setShowDetail(true);
        setDetailOwner(response.data);
        setOwnerDisabled(response.data.is_activate);
        setSwitchActive(response.data.is_activate);
      })
      .catch((_) => {
        setShowDetail(false);
        Alert(t("General.Message.ErrorService"), "error");
      });
  };

  useEffect(() => {
    getDetailMerchant();
  }, []);

  const [editEmail, setEditEmail] = useState(false);
  const handleEditEmail = (isEditable) => {
    setEditEmail(isEditable);
  };

  function showOwnerStatus() {
    if (ownerDisabled) return t("Owners.Message.UserEnable");
    return t("Owners.Message.UserDisable");
  }

  function showEditEmail(isEditable) {
    if (!isEditable) return detailOwner.email;
    return (
      <Fragment>
        <TextField id="owner-email" size="small" label={detailOwner.email} />
        <TaskAltIcon
          sx={{ marginLeft: 2 }}
          className="Cursor"
          onClick={() => {
            handleUpdateOwner(false, true);
          }}
        />
        <DoDisturbIcon
          sx={{ marginLeft: 2 }}
          className="Cursor"
          onClick={() => {
            handleEditEmail(false);
          }}
        />
      </Fragment>
    );
  }

  function showBankAccount(detail) {
    if (detail.account !== undefined && detail.account !== null)
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            sx={{ borderStyle: "none" }}
            className={"card-grid-padding"}
          >
            {Object.entries(detail.account).map((item, index) => (
              <Fragment key={index}>
                <Grid item xs={2}>
                  <Tag>{t(`Owners.BankAccount.${item[0]}`)}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{item[1]}</Content>
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={2}>
              <Tag>{t("Owners.BankAccount.BankVoucher")}</Tag>
            </Grid>
            <Grid item xs={7}>
              <Content>
                <VisibilityIcon
                  className="Cursor"
                  data-testid="VisibilityIcon"
                  sx={{ marginRight: 2 }}
                  onClick={() => statementUrl("view")}
                />
                <PermissionsGate scopes={"merchants.actions.DownloadBankFile"}>
                  <CloudDownloadIcon
                    data-testid="CloudDownloadIcon"
                    className="Cursor"
                    onClick={() => statementUrl("download")}
                  />
                </PermissionsGate>
              </Content>
            </Grid>
            <Grid item xs={3}>
              <Box alignItems="right">
                {detail.status !== undefined &&
                detail.status !== null &&
                detail.status === "pending" ? (
                  <Fragment>
                    <PermissionsGate
                      scopes={"merchants.actions.AcceptBakAccount"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<DoneIcon />}
                        color="success"
                        sx={{ marginRight: 2 }}
                        onClick={async () => {
                          let isConfirm = await Confirm(
                            "",
                            t("Owners.Message.AccountStatusAccept"),
                            "warning"
                          );
                          if (isConfirm) updateStatusAccount("accepted");
                        }}
                      >
                        {t("Owners.BankAccount.Accept")}
                      </Button>
                    </PermissionsGate>
                    <PermissionsGate
                      scopes={"merchants.actions.RejectBakAccount"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<ClearIcon />}
                        color="error"
                        onClick={async () => {
                          if (
                            await Confirm(
                              "",
                              t("Owners.Message.AccountStatusReject"),
                              "warning"
                            )
                          )
                            updateStatusAccount("rejected");
                        }}
                      >
                        {t("Owners.BankAccount.Reject")}
                      </Button>
                    </PermissionsGate>
                  </Fragment>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      );

    return (
      <div style={{ height: "50vh" }}>
        <div
          style={{
            position: "relative",
            left: "80%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {t("General.Message.NoData")}
        </div>
      </div>
    );
  }

  const fnGate = (tabName, foundRedirect) => {
    arrTabs.push(tabName);
    let label;
    let Weight;
    let color;
    switch (tabName) {
      case "Profile":
        label = "Owners.Profile.";
        break;
      case "BankAccount":
        label = "Owners.BankAccount.";
        break;
      case "Transfers":
        label = "Owners.Transfers.";
        break;
      case "Branches":
        label = "Owners.Branches.";
        break;
      default:
        break;
    }
    let scope =
      label +
      tabName.charAt(0).toLowerCase() +
      tabName.slice(1) +
      (tabName.endsWith("s") ? "" : "s") +
      ".actions.view";

    if (render == tabName) {
      Weight = 500;
      color = "#404A56";
    } else {
      Weight = 400;
      color = "#4C5866";
    }

    return (
      <PermissionsGate scopes={scope}>
        <Tab
          label={t(label + tabName)}
          onClick={() => handleTabs(tabName)}
          style={{
            minWidth: "172px",
            fontFamily: "Roboto",
            fontSize: "18px",
            color: color,
            opacity: 100,
            fontWeight: Weight,
          }}
        />
      </PermissionsGate>
    );
  };

  const handleTabs = (tabName) => {
    setRender(tabName);
    const index = arrTabs.indexOf(tabName);
    setValue(index);
  };

  const renderiza = () => {
    switch (render) {
      case "BankAccount": {
        return (
          <Card style={{ borderRadius: "20px" }}>
            {showBankAccount(detailOwner)}
          </Card>
        );
      }
      case "Profile": {
        return (
          <Card style={{ borderRadius: "20px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{ borderStyle: "none" }}
                className={"card-grid-padding"}
              >
                <Grid item xs={12} sx={{ borderStyle: "none" }}>
                  <Title>{t("Owners.Profile.BusinessData")}</Title>
                </Grid>
                <Grid item xs={2}>
                  <Tag>{t("Owners.Profile.Merchant")}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{detailOwner.business_name}</Content>
                </Grid>
                <Grid item xs={2}>
                  <Tag>{t("Owners.Profile.Id")}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{detailOwner.wallet_id}</Content>
                </Grid>
                <Grid item xs={2}>
                  <Tag>{t("Owners.Profile.UsersName")}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{detailOwner.name}</Content>
                </Grid>
                <Grid item xs={12}>
                  <Title>{t("Owners.Profile.AccountData")}</Title>
                </Grid>
                <Grid item xs={2}>
                  <Tag>{t("Owners.Profile.Email")}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{showEditEmail(editEmail)}</Content>
                </Grid>
                <Grid item xs={2}>
                  <Tag> {t("Owners.Profile.Password")}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>
                    <Chip
                      label={t("Owners.Profile.Edit")}
                      color="info"
                      variant="outlined"
                      clickable={!editEmail}
                      disable={editEmail.toString()}
                      style={{ borderColor: "#FFAB31" }}
                      onClick={() => {
                        handleEditEmail(true);
                      }}
                    />
                  </Content>
                </Grid>
              </Grid>
            </Box>
          </Card>
        );
      }
      case "Transfers": {
        return <CashOut ownerId={client_id} />;
      }
      case "Branches": {
        switch (screen) {
          case "qr":
            return <div className="App">{screenQr()}</div>;
          case "detail":
            return <div className="App">{screenDetail()}</div>;
          case "stores":
            return <div className="App">{screenStores()}</div>;
        }
      }
    }
  };

  const screenStores = () => {
    return (
      <AggridTable
        columnDefs={columnsStore}
        pagination={true}
        params={""}
        upperCase={false}
        height={400}
        width={"100%"}
        rowdata={detailOwner.stores}
        showsearch={false}
      />
    );
  };

  return (
    <Fragment>
      {showDetail ? (
        <div>
          <Card style={{ borderRadius: "10px" }}>
            <Grid
              container
              direction="row"
              style={{ justifyContent: "space-between" }}
            >
              <Grid item md={6} xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Box display="flex" justifyContent="left" alignItems="left">
                      <CardContent
                        sx={{
                          textAlign: "left",
                          paddingBottom: "0px !important",
                          paddingTop: "0px !important",
                          width: "100%",
                        }}
                      >
                        <Grid container>
                          <Grid item md={1}>
                            <Typography
                              variant="h3"
                              style={{ marginTop: "13px" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                sx={{
                                  bgcolor: "#DADADA",
                                  width: 55,
                                  height: 55,
                                }}
                              >
                                <PersonIcon className="Cursor" fontSize="40" />
                              </Avatar>
                            </Typography>
                          </Grid>
                          <Grid item md={10} sx={{ paddingLeft: "10px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "24px",
                                fontWeight: "700",
                                marginTop: "20px",
                                lineHeight: "18px",
                              }}
                            >
                              {detailOwner.business_name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "10px",
                                lineHeight: "18px",
                              }}
                            >
                              {t("Owners.Profile.Merchant")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Box
                      display="flex"
                      justifyContent="right"
                      alignItems="right"
                      style={{ marginTop: "20px" }}
                      mt={4}
                    >
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        mr={3}
                      >
                        {showOwnerStatus()}
                      </Typography>
                      <Switch
                        checked={switchActive}
                        onChange={handleChangeDisable}
                        inputProps={{
                          "aria-label": "controlled",
                          "data-testid": "checkId",
                        }}
                        sx={{ marginTop: -1 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Fragment>
            <Box
              sx={{ borderBottom: 1, borderColor: "#DFE3E7" }}
              mt={3}
              mr={3}
              ml={0}
            >
              <Tabs value={value}>
                {fnGate("Profile")}
                {fnGate("BankAccount")}
                {fnGate("Branches")}
              </Tabs>
            </Box>
            <div style={{ paddingTop: "30px" }}>{renderiza()}</div>
          </Fragment>
        </div>
      ) : null}
      <Dialog fullScreen={fullScreen} open={openImage} onClose={handleClose}>
        <DialogContent>
          <img
            data-testid="asd"
            src={urlImage}
            alt="asd"
            width="100%"
            height="100%"
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default OwnerDetail;
