import { CardContent } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";
import Sesion from "../components/commons/sesion";
import Micromerchant from "../components/micromerchants/micromerchant";
import MicromerchantDetail from "../components/micromerchants/micromerchantDetail";
import Theme from "../themes/Theme";

export default function Micromerchants() {
  const { client_id } = useParams();

  return (
    <div className="Drawer">
      <CardContent>
        <Sesion screen={"Micromerchants"} />
        <ThemeProvider theme={Theme}>
          {client_id !== undefined ? (
            <MicromerchantDetail />
          ) : (
            <Micromerchant />
          )}
        </ThemeProvider>
      </CardContent>
    </div>
  );
}
