import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const getkey = (dataobj) => {
  try {
    let obj = JSON.parse(dataobj);
    let currentkey;
    for (const [keys] of Object.entries(obj)) {
      currentkey = keys;
    }
    return currentkey;
  } catch (error) {
    return "";
  }
};

const getval = (dataobj) => {
  try {
    let obj = JSON.parse(dataobj);
    let currentdata;
    for (const [, val] of Object.entries(obj)) {
      currentdata = val;
    }
    return currentdata;
  } catch (error) {
    return "";
  }
};

const EditParams = ({ getvalue, index, data, deleteItem }) => {
  const [key, setkey] = React.useState(getkey(data));
  const [value, setvalue] = React.useState(getval(data));

  const validateValue = (objkey, inputValue, indexKey) => {
    if (objkey !== "" && inputValue !== "") {
      getvalue(objkey, inputValue, indexKey);
    }
  };

  const handleText = (event) => {
    setkey(event.target.value);
    validateValue(event.target.value, value, index);
  };

  useEffect(() => {
    setkey(getkey(data));
    setvalue(getval(data));
  }, [data]);

  const handleSelect = (event) => {
    setvalue(event.target.value);
    validateValue(key, event.target.value, index);
  };

  return (
    <Grid container alignItems={"center"} style={{ marginBottom: "3%" }}>
      <Grid item xs={5}>
        <TextField
          id="outlined-uncontrolled"
          label="key"
          //value={key}
          value={key}
          onChange={handleText}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-select-small">value</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            data-testid="select-value"
            value={value}
            label="value"
            onChange={handleSelect}
          >
            <MenuItem value={"{requestId}"}>requestId</MenuItem>
            <MenuItem value={"{wallet_id}"}>wallet_id</MenuItem>
            <MenuItem value={"{username}"}>username</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <DeleteForeverIcon
          style={{ color: "red", fontSize: "35px" }}
          data-testid="delete-item"
          onClick={() => deleteItem(key)}
        />
      </Grid>
    </Grid>
  );
};

export default EditParams;
