import React from "react";
import Sesion from "../components/commons/sesion";

export default function Payments() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Payments"} />
    </div>
  );
}
