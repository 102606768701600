import React, { Fragment, useEffect, useState } from "react";
import BasicTable from "../commons/basicTable";
import TableCell from "@mui/material/TableCell";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Buttons from "./buttons";
import GenericBackdrop from "../commons/GenericBackdrop";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DataTableFormat } from "../commons/setDataTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function Transfers() {
  const [t] = useTranslation("global");
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [filterParams, setfilterParams] = useState({});
  const [loader, setLoader] = useState(false);
  const [columnsSearch, setColumnsSearch] = useState([]);
  const [dataBackUp, setDataBackUp] = useState([]);
  const [columns, setColumns] = useState([]);
  const [datesFilter, setDatesFilter] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const condition = ["status", "merchant_id"];

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getBalanceDetail = async () => {
      handleLoaderOpen();
      try {
        const response = await axiosPrivate.get(
          `transactions-internal`,
          {
            params: {
              ...filterParams,
            },
          },
          {
            signal: controller.signal,
          }
        );
        isMounted &&
          DataTableFormat(
            response,
            setColumnsSearch,
            setDataBackUp,
            setColumns,
            setData,
            setDatesFilter
          );
        setLoader(false);
        handleLoaderClose();
      } catch (err) {
        console.error(err);
        setLoader(false);
      }
    };
    if (!isSearch && filterParams.branch != undefined) {
      getBalanceDetail();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isSearch, filterParams]);

  const handleCallback = (childData) => {
    setfilterParams(childData);
  };

  const setButtons = (results) => {
    if (results.status === "OK") {
      return (
        <Button
          data-testid="buttonOk"
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#DEF3DD",
            color: "#59C556",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<CheckIcon />}
        >
          {t(`Insights.Transactions.Table.StatusLiquidated`)}
        </Button>
      );
    } else if (results.status === "ERROR") {
      return (
        <Button
          data-testid="buttonError"
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#EFCCD2",
            color: "#B00020",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<CloseIcon />}
        >
          {t(`Insights.Transactions.Table.StatusError`)}
        </Button>
      );
    } else if (results.status === "REVERTED") {
      return (
        <Button
          data-testid="buttonRefunded"
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusRefunded`)}
        </Button>
      );
    } else {
      return (
        <Button
          data-testid="buttonReversed"
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusReversed`)}
        </Button>
      );
    }
  };

  const handleClick = (row) => {
    navigate("/TransfersDetail", {
      screen: "TransfersDetail",
      state: {
        type: row,
      },
    });
  };

  const createaction = (row) => {
    return (
      <Fragment key={uuidv4()}>
        <TableCell align="center">{setButtons(row)}</TableCell>
        <TableCell align="center" onClick={() => handleClick(row)}>
          {
            <SettingsIcon
              data-testid={`SettingsIcon-${row.transaction_id}`}
              style={{ cursor: "hand" }}
            />
          }
        </TableCell>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Buttons
        data={data}
        columns={columns}
        backUp={dataBackUp}
        setSearch={setData}
        columnsSearch={columnsSearch}
        setIsSearch={setIsSearch}
        dataFilter={handleCallback}
        dateDefaultFilter={datesFilter}
        filterEmail={{ ...filterParams }}
      />
      <BasicTable
        dataTable={data}
        dataColumns={columns}
        action={createaction}
        condition={condition}
      />
    </Fragment>
  );
}
