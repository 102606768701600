import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

import BankAccountValidation from "./validations/bank_account_validation";
import PermissionsGate from "../commons/PermissionsGate";

function Operation() {
  const [t] = useTranslation("global");

  const [value, setValue] = React.useState(0);

  const tabStyle = {
    "&.Mui-selected": { color: "#404A56" },
    color: "#4C5866",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  function renderPanel() {
    if (value === 0) {
      return <BankAccountValidation />;
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ "& .MuiTabs-indicator": { backgroundColor: "#004FB6" } }}
        >
          <PermissionsGate scopes={"resources.configuration"}>
            <Tab
              label={t("Operations.BankAccountValidations.TabName")}
              sx={tabStyle}
            />
          </PermissionsGate>
        </Tabs>
      </Box>
      {renderPanel()}
    </Box>
  );
}

export default Operation;
