import React, { Fragment } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import PermissionsGate from "../commons/PermissionsGate";
import TabPanel from "../../utils/tabPanel";
import ChangeLevel from "./change_level/ChangeLevel";

function Micromerchant() {
  const [t] = useTranslation("global");
  return (
    <Fragment>
      <Box
        sx={{ borderBottom: 1, borderColor: "#DFE3E7;", margin: "0 0 33px 0" }}
      >
        <Tabs value={0} data-testid="tabs">
          <Tab
            label={t("Micromerchants.LevelChange.TabName")}
            data-testid="tab"
          />
        </Tabs>
      </Box>
      <PermissionsGate scopes={"resources.micromerchants"}>
        <TabPanel value={0} index={0}>
          <ChangeLevel />
        </TabPanel>
      </PermissionsGate>
    </Fragment>
  );
}

export default Micromerchant;
