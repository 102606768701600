import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import SideBar from "./views/sideBar";
import Configuration from "./views/Configuration";
import IntegratorConfiguration from "./views/IntegratorConfiguration";
import Help from "./views/Help";
import Transfer from "./views/Transfer";
import Operations from "./views/Operations";
import Insights from "./views/Insights";
import Payments from "./views/Payments";
import Merchants from "./views/Merchants";
import SignIn from "./views/SignIn";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Micromerchants from "./views/Micromerchants";

const ROLES = {
  Read: "Read",
  Owner: "Owner",
  Standard: "Standard",
  DevUser: "Dev User",
  Viewer: "Viewer",
  Admin: "Admin",
};

function App() {
  return (
    <>
      <SideBar />
      <Routes>
        <Route exact path="/SignIn" element={<SignIn />} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route exact path="/index.html" element={<Insights />} />
            <Route exact path="/" element={<Insights />} />
            <Route exact path="/Configuration" element={<Configuration />} />
            <Route
              exact
              path="/IntegratorConfiguration"
              element={<IntegratorConfiguration />}
            />
            <Route exact path="/Transfer" element={<Transfer />} />
            <Route exact path="/Operations" element={<Operations />} />
            <Route exact path="/Insights" element={<Insights />} />
            <Route exact path="/Merchants" element={<Merchants />} />
            <Route path="/Merchants/:ownerId" element={<Merchants />} />
            <Route exact path="/Payments" element={<Payments />} />
            <Route exact path="/Help" element={<Help />} />
            <Route exact path="/Micromerchants" element={<Micromerchants />} />
            <Route
              exact
              path="/Micromerchants/:client_id"
              element={<Micromerchants />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Missing />} />
      </Routes>
    </>
  );
}

export default App;
