import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../utils/tabPanel";
import Callback from "./integrator/callback";
import Cron from "./integrator/cron";

const Integratorconfig = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  return (
    <Fragment>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#DFE3E7;",
          margin: "0 0 33px 0",
        }}
      >
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={"Callback"} />
          <Tab label={"Cron"} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Callback />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Cron />
      </TabPanel>
    </Fragment>
  );
};

export default Integratorconfig;
