import React, { Fragment, useState, useEffect } from "react";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CardContent,
  CircularProgress,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import countries from "../../../translations/country.json";
import { axiosClient, axiosClientKym } from "../../commons/axiosClient";
import { ExportExcel } from "../../../utils/exportExcel";
import { SnackBar } from "../../../components/utils/alert";
import BasicTable from "../../commons/basicTable";
import DynamicAsyncAction from "../../aggrid/DynamicAsyncAction";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";

const ChangeLevel = () => {
  const [t] = useTranslation("global");
  const [level, setLevel] = useState("");
  const [showLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const handleShowTable = () => setLoading(false);
  const handleHideTable = () => setLoading(true);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getDataTable();
    if (localStorage.getItem("MicromerchantDetail") != null) {
      workLocalStorage("set");
    } else {
      workLocalStorage("remove");
    }
  }, []);

  const getHours = (obj1, obj2) => {
    let hours = {
      same: [],
      different: [],
    };

    hours["same"] = obj1
      .filter((x) =>
        obj2.some(
          (j) =>
            x["first_time"] == j["first_time"] &&
            x["last_time"] == j["last_time"] &&
            x["type"] != j["type"]
        )
      )
      .map((x) => x["type"]);

    hours["different"] = obj1
      .filter((x) => !hours["same"].includes(x["type"]))
      .map((x) => x["type"]);

    return hours;
  };

  const getDataCsv = (data, all) => {
    let country = getCountryPage();
    let level;
    if (country == "bo") {
      level = "6";
    } else if (country == "py") {
      level = "1";
    } else {
      level = "2";
    }

    let tmt;
    if (!all) {
      tmt = data
        .filter((item) => item.level === "5000")
        .map((item) => {
          return {
            msisdn: item["msisdn"],
            client_id: item["client_id"],
            test: "test",
            prueba: "prueba",
            level: level,
          };
        });
    } else {
      tmt = data.map((item) => {
        let time = getHours(
          item["time"].filter(
            (x) => x["active"] && !x["all_day_open"] && !x["closed"]
          ),
          item["time"].filter(
            (x) => x["active"] && !x["all_day_open"] && !x["closed"]
          )
        );

        return {
          msisdn: item["msisdn"],
          client_id: item["client_id"],
          application_date: item["application_date"],
          level: item["level"],
          email: item["email"],
          active: item["is_active"],
          country: item["country"],
          terms_conditions: item["terms_conditions"],
          public_name: item["public_name"],
          business_category: item["business_category"],
          user: item["user"],
          business_description: item["business_description"],
          state: item["state"],
          city: item["city"],
          street_and_number: item["street_and_number"],
          post_code: item["post_code"],
          reference: item["reference"],
          website: item["website"],
          phone: item["phone"],
          social: item["social"],
          "24hrs": item["time"]
            .filter((x) => x["active"] && x["all_day_open"])
            .map((x) => x["type"]),
          closed: item["time"]
            .filter((x) => x["closed"])
            .map((ele) => ele["type"]),
          days_with_the_same_shedule: time["same"],
          days_with_different_hours: time["different"],
        };
      });
    }
    return tmt;
  };

  const refreshUser = (user) => {
    const userIndex = data.findIndex(
      (item) => item["client_id"] === user["client_id"]
    );
    if (userIndex < 0) return;
    setData((data) => {
      data[userIndex] = { ...user };
      return [...data];
    });
  };

  const workLocalStorage = (type) => {
    if (type == "set") {
      if (localStorage.getItem("Micromerchant-rowsPerPage") != null) {
        setRowPerPage(
          parseInt(localStorage.getItem("Micromerchant-rowsPerPage"))
        );
      }
      if (localStorage.getItem("Micromerchant-page") != null) {
        setPage(parseInt(localStorage.getItem("Micromerchant-page")));
      }
      if (localStorage.getItem("Micromerchant-level") != null) {
        setLevel(localStorage.getItem("Micromerchant-level"));
      }
    } else {
      localStorage.removeItem("Micromerchant-page");
      localStorage.removeItem("Micromerchant-rowsPerPage");
      localStorage.removeItem("Micromerchant-level");
    }
  };

  const updateClientHandler = async (props, e) => {
    workLocalStorage("set");
    e.preventDefault();
    const active = !props["is_active"];
    let client = { ...props, isLoading: true, is_active: active };
    refreshUser(client);
    const micromerchant = {
      business_profile: {
        is_active: active,
      },
      physicalstore: {},
      onlinestore: {},
      docs: {},
      micromerchantadditional: {},
    };
    client = (await getUpdateClient(client, micromerchant).catch((_) => {
      SnackBar(t("Micromerchants.LevelChange.Message.Error.Reload"), "error");
    })) ?? { ...props };
    refreshUser({ ...client, isLoading: false });
    workLocalStorage("set");
  };

  const getUpdateClient = async (client, micromerchant) => {
    const clientUpdate = await getClientKymById(client["client_id"]);
    if (parseInt(clientUpdate["level"]) !== 5000) {
      await updateClientById(client["client_id"], micromerchant);
    }
    return clientUpdate;
  };

  const handleLevelChange = (e) => {
    workLocalStorage("remove");
    setPage(0);
    setRowPerPage(10);
    localStorage.setItem("Micromerchant-level", e.target.value);
    setLevel(e.target.value);
  };

  const getCountryPage = () => {
    let result = window.location.origin;
    let country = "";
    let div = result.split(".");
    for (let x in div) {
      for (let y in Object.keys(countries)) {
        if (div[x] === Object.keys(countries)[y]) {
          country = div[x].toString();
          break;
        }
      }
    }
    return country;
  };

  const getDataTable = () => {
    handleHideTable();
    const url = "micromerchants";
    const country = getCountryPage();
    axiosClientKym
      .get(url, { params: { country: country } })
      .then((response) => {
        setData(response.data.results);
        handleShowTable();
      });
  };

  const getClientKymById = async (id) => {
    const country = getCountryPage();
    const url = `micromerchant/${id}`;
    return axiosClientKym
      .get(url, { params: { country: country } })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  };

  const updateClientById = async (id, body) => {
    const url = `micromerchant/${id}`;
    return axiosClient
      .patch(url, { ...body })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const TableContent = (props) => {
    const content = props.isLoading ? (
      <BoxLoaderInner />
    ) : (
      <AagridTableMicroMerchant />
    );
    return <div>{content}</div>;
  };

  const BoxLoaderInner = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <CardContent data-testid={"loading-change-bank-status"}>
          <CircularProgress
            data-testid={"loading-change-cash-out-status"}
            color="warning"
          />
        </CardContent>
      </Box>
    );
  };

  const setTypeAction = (data) => {
    const level = parseInt(data["level"]);
    if (level === 5000) {
      return "ReloadAction";
    }
    return "SwitchAction";
  };

  const createaction = (row) => {
    const typeAction = setTypeAction(row);
    return (
      <Fragment>
        <Fragment>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <DynamicAsyncAction
              action={typeAction}
              reload={updateClientHandler}
              data={row}
              active={row["is_active"]}
            />

            <Tooltip title={"Detalle"}>
              <IconButton className="Cursor">
                <Link
                  to={`/Micromerchants/${row.client_id}`}
                  className="ag-link-icon"
                  state={row}
                  style={{ color: "#707070" }}
                >
                  <RemoveRedEyeIcon />
                </Link>
              </IconButton>
            </Tooltip>
          </TableCell>
        </Fragment>
      </Fragment>
    );
  };

  const AagridTableMicroMerchant = () => {
    return (
      <BasicTable
        dataTable={filteredData}
        dataColumns={[
          t("Micromerchants.LevelChange.Columns.ColMsisdn"),
          t("Micromerchants.LevelChange.Columns.ColClienId"),
          t("Micromerchants.LevelChange.Columns.ColStatusForm"),
          t("Micromerchants.LevelChange.Columns.ColApplicationDate"),
          t("Micromerchants.LevelChange.Columns.ColLevel"),
          t("Micromerchants.LevelChange.Columns.ColUserStatus"),
          t("Micromerchants.LevelChange.Columns.Actions"),
        ]}
        action={createaction}
        condition={[
          "_id",
          "email",
          "isLoading",
          "is_active",
          "country",
          "terms_conditions",
          "public_name",
          "business_category",
          "user",
          "business_description",
          "state",
          "city",
          "street_and_number",
          "post_code",
          "reference",
          "website",
          "phone",
          "social",
          "time",
        ]}
        dinamicAction={[
          { field: "form_status", icon: "MicromerchantsFormStatus" },
          { field: "user_status", icon: "MicromerchantsUserStatus" },
        ]}
        rowsPerPageOpt={[10, 25, 50]}
        tableType={"Micromerchant"}
        rowsPerPageValue={rowsPerPage}
        pageValue={page}
      />
    );
  };

  const filteredData =
    level !== ""
      ? data.filter((item) => {
          return item["level"] === level;
        })
      : data;

  return (
    <Fragment>
      <Stack direction="row" width="100%" mb={2}>
        <Stack direction="row" width="150vh" spacing={1} justifyContent="start">
          <FormControl sx={{ minWidth: 160 }}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("Micromerchants.LevelChange.SelectLevel")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={level}
              label="Age"
              onChange={handleLevelChange}
              data-testid="select"
              sx={{ paddingBottom: "0px", borderRadius: "16px" }}
            >
              <MenuItem value="">
                <em>{t("Micromerchants.LevelChange.SelectOption")}</em>
              </MenuItem>
              <MenuItem value={"5000"}>5000</MenuItem>
              <MenuItem value={"6000"}>6000</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          width="150vh"
          spacing={1}
          justifyContent="end"
          alignItems="center"
        >
          <ExportExcel
            fileType={"csv"}
            fileName={"AllDataMicromerchant"}
            data={getDataCsv(data, true)}
            columns={[
              "msisdn",
              "client_id",
              "application_date",
              "level",
              "email",
              "active",
              "country",
              "terms_conditions",
              "public_name",
              "business_category",
              "user",
              "business_description",
              "state",
              "city",
              "street_and_number",
              "post_code",
              "reference",
              "website",
              "phone",
              "social",
              "24hrs",
              "closed",
              "days_with_the_same_shedule",
              "days_with_different_hours",
            ]}
            textButton={t("Micromerchants.LevelChange.Buttons.ExportAll")}
            iconDownload={true}
          />

          <ExportExcel
            fileType={"csv"}
            fileName={"NewMicromerchant"}
            data={getDataCsv(data, false)}
            columns={["client_id", "msisdn", "test", "prueba", "level"]}
            showHeader={false}
            delimiter={";"}
            rowDelimiter={"\r\n"}
          />
        </Stack>
      </Stack>

      <Fragment>
        <TableContent isLoading={showLoading} />
      </Fragment>
    </Fragment>
  );
};

export default ChangeLevel;
