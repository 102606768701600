import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { axiosClient } from "../commons/axiosClient";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useTranslation } from "react-i18next";
import "../../Styles/App.scss";
import GenericBackdrop from "../../utils/GenericBackdrop";
import { SnackBar } from "../utils/alert";
import PopupEditCron from "../configuration/integrator/PopupEditCron";
import PopupEditParams from "../configuration/integrator/PopupEditParams";
import PopupEditInformation from "../configuration/integrator/PopupEditInformation";
import PermissionsGate from "../commons/PermissionsGate";
import Search from "../commons/search";

const AggridTable = ({
  columnDefs,
  pagination,
  url,
  params,
  upperCase,
  height,
  width,
  rowdata,
  showsearch = true,
  edit = false,
  ValidateonRowValue = false,
  startEditingcolumn = "",
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [data, setdata] = useState([]);
  const [dataBack, setdataBack] = useState([]);
  const [columnsSearch, setColumnsSearch] = useState([]);
  const [t] = useTranslation("global");
  const modules = useMemo(
    () => [ClientSideRowModelModule, CsvExportModule],
    []
  );
  const gridRef = useRef();
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const onGridReady = (param) => {
    setGridApi(param.api);
  };

  function getData() {
    handleLoaderOpen();

    if (rowdata) {
      setdata(rowdata);
      handleLoaderClose();
    } else {
      axiosClient
        .get(url, isSearch ? { params: { ...params, isSearch } } : { params })
        .then((response) => {
          handleLoaderClose();
          setdata(checkResult(response));
          setdataBack(checkResult(response));
          let cols = [];
          for (let clave in response.data.results[0]) {
            let typeVariable = typeof response.data.results[0][clave];
            if (typeVariable === "string") cols.push(clave);
          }
          setColumnsSearch(cols);
        })
        .catch((err) => {
          console.log(err);

          handleLoaderClose();
          SnackBar(t("General.Message.ErrorService"), "error");
        });
    }
  }

  function checkResult(response) {
    if (response.data.results !== undefined) return response.data.results;
    else if (response.data !== "") return response.data;
    else return [];
  }

  useEffect(() => {
    getData();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
    };
  }, []);

  // Guardar por get y set desde las columnas
  const onCellValueChanged = useCallback((event) => {
    if (event.oldValue !== event.newValue) {
      setValidation(true);
    }
  }, []);

  const cleardata = () => {
    setdata([]);
    getData();
  };

  const updateValue = (method, upurl, updata) => {
    axiosClient({
      method,
      url: upurl,
      data: updata,
    })
      .then(() => {
        SnackBar(t("AGgrid.Message.Sucess"), "success", "#DFFFEA");
        cleardata();
        handleLoaderClose();
      })
      .catch((err) => {
        console.log(err);
        SnackBar(t("General.Message.ErrorService"), "error");
        cleardata();
        handleLoaderClose();
      });
  };

  const onRowValueChanged = useCallback(
    (event) => {
      let method;
      let upurl = url;
      if (!event.data.id) {
        method = "post";
      } else {
        method = "put";
        upurl = `${url}/${event.data.id}`;
      }
      handleLoaderOpen();
      if (validation) {
        if (typeof ValidateonRowValue == "function") {
          if (ValidateonRowValue(event.data)) {
            updateValue(method, upurl, event.data);
          } else {
            SnackBar(t("General.Message.ErrorUrl"), "error");
            cleardata();
            handleLoaderClose();
          }
        } else {
          updateValue(method, upurl, event.data);
        }
        setValidation(false);
      } else {
        gridRef.current.api.stopEditing();
        handleLoaderClose();
      }
    },
    [validation]
  );

  const frameworkComponents = {
    popupEditCron: PopupEditCron,
    popupEditParams: PopupEditParams,
    popupEditInfo: PopupEditInformation,
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Grid item xs={6}>
        <PermissionsGate scopes={"merchants.actions.search"}>
          {showsearch ? (
            <Search
              backUp={dataBack}
              setSearch={setdata}
              columnsSearch={columnsSearch}
              setIsSearch={setIsSearch}
            />
          ) : (
            <div></div>
          )}
        </PermissionsGate>
      </Grid>
      <Card style={{ borderRadius: "5px" }}>
        <CardContent>
          <Grid container direction="row">
            <Grid container item xs={6} style={{ justifyContent: "end" }}>
              <Button
                style={{
                  borderColor: "white",
                  margin: "10px",
                  height: "min-content",
                  borderRadius: "10px",
                  textTransform: "none",
                  display: "none",
                  backgroundColor: "white",
                  color: "green",
                }}
                variant="outlined"
                startIcon={<ImportExportIcon />}
                onClick={() => {
                  onBtnExport();
                }}
              >
                {t("AGgrid.ExportToCSV")}
              </Button>
            </Grid>
          </Grid>
          <div
            id="myGrid"
            className="ag-theme-material"
            style={{ height, width, fontFamily: "Roboto" }}
          >
            <PermissionsGate scopes={"merchants.actions.viewList"}>
              <AgGridReact
                reactUi="true"
                animateRows="true"
                rowData={data}
                columnDefs={columnDefs}
                pagination={pagination}
                modules={modules}
                defaultColDef={defaultColDef}
                onCellValueChanged={onCellValueChanged}
                rowSelection={"single"}
                // van juntos onRow y editType para guardar toda la fila entera
                onRowValueChanged={edit && onRowValueChanged}
                editType={edit && "fullRow"}
                suppressExcelExport={true}
                popupParent={document.body}
                onGridReady={onGridReady}
                suppressRowClickSelection="true"
                ref={gridRef}
                frameworkComponents={frameworkComponents}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                copyHeadersToClipboard={true}
                sortingOrder={sortingOrder}
              ></AgGridReact>
            </PermissionsGate>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default AggridTable;
