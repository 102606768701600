import React, { Fragment, useState } from "react";
import AggridTable from "../../aggrid/aggridTable";
import { useTranslation } from "react-i18next";
import { DynamicAction } from "../../aggrid/dynamicAction";
import { axiosClient } from "../../commons/axiosClient";
import { SnackBar } from "../../utils/alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { ColumnsGenerator } from "../columnGenerator";

function ImageViewer({ onClose, open, url }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Fragment>
      <Dialog
        data-testid="image-show-account"
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <img src={url} alt="asd" width="100%" height="100%" />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default function BankAccountValidation({ ownerId }) {
  const [t] = useTranslation("global");
  const getUrl = () =>
    `withdrawals/bank-accounts/${ownerId || ""}${ownerId ? "/" : ""}`;
  const columns = ColumnsGenerator([
    {
      headerName: t("Operations.BankAccountValidations.Merchant"),
      field: "merchant",
      minWidth: 100,
      maxWidth: 140,
      cellStyle: () => ({
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
      }),
    },
    {
      headerName: t("Operations.BankAccountValidations.Name"),
      field: "name",
      minWidth: 180,
      cellStyle: () => ({
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
      }),
    },
    {
      headerName: t("Operations.BankAccountValidations.Bank"),
      field: "bankName",
      minWidth: 120,
      cellStyle: () => ({
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
      }),
    },
    {
      headerName: t("Operations.BankAccountValidations.AccountType"),
      field: "type",
      minWidth: 170,
    },
    {
      headerName: t("Operations.BankAccountValidations.AccountNumber"),
      field: "account",
      minWidth: 150,
    },
    {
      headerName: t("Operations.BankAccountValidations.Bill"),
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        showProofBankAccount: ShowProofBankAccount,
        icon: "ProofBankAccountView",
      },
      minWidth: 80,
    },
    {
      headerName: t("Operations.BankAccountValidations.Status.Header"),
      field: "status",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "BankAccountStatus" },
      minWidth: 155,
    },
    {
      headerName: t("Operations.BankAccountValidations.Actions.Header"),
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        acceptAccount: AcceptAccount,
        rejectAccount: RejectAccount,
        icon: "BankAccountActions",
      },
      minWidth: 100,
    },
  ]);

  const [imageUrl, setImageUrl] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const handleShowTale = () => setShowTable(true);
  const handleHideTale = () => setShowTable(false);

  const handleOpen = () => setShowImage(true);
  const handleClose = () => setShowImage(false);

  function updateStatusAccount(status, account_id) {
    handleHideTale();
    const urlAccount = `${getUrl()}${account_id}/status`;
    axiosClient
      .patch(urlAccount, JSON.stringify({ status: status }))
      .then((_) => {
        SnackBar(t("Owners.Message.AccountStatus"), "success");
        handleShowTale();
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
        handleShowTale();
      });
  }

  function AcceptAccount(props, e) {
    e.preventDefault();
    updateStatusAccount("accepted", props.data.account_id);
  }

  function RejectAccount(props, e) {
    e.preventDefault();
    updateStatusAccount("rejected", props.data.account_id);
  }

  function ShowProofBankAccount(props, _) {
    const [bucket, key] = props.data.statement;

    const url = "merchants/files/";

    axiosClient
      .get(url, {
        params: {
          bucket,
          key,
          isView: true,
          isDownload: false,
        },
      })
      .then((response) => {
        setImageUrl(response.data);
        handleOpen();
      });
  }

  function showTableAgGrid() {
    if (showTable) {
      return (
        <AggridTable
          columnDefs={columns}
          pagination={true}
          height={600}
          url={getUrl()}
          width={"100%"}
        />
      );
    }
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <CardContent data-testid={"loading-change-bank-status"}>
          <CircularProgress color="warning" />
        </CardContent>
      </Box>
    );
  }

  return (
    <Fragment>
      {showTableAgGrid()}
      <ImageViewer onClose={handleClose} open={showImage} url={imageUrl} />
    </Fragment>
  );
}
