import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../utils/tabPanel";
import Help from "./help/help";
import PermissionsGate from "../commons/PermissionsGate";

const Configurations = () => {
  const [t] = useTranslation("global");

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  return (
    <Fragment>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#DFE3E7;",
          margin: "0 0 33px 0",
        }}
      >
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={t("Configuration.Help.TabName")} />
        </Tabs>
      </Box>
      <PermissionsGate scopes={"resources.configuration"}>
        <TabPanel value={tab} index={0}>
          <Help />
        </TabPanel>
      </PermissionsGate>
    </Fragment>
  );
};

export default Configurations;
