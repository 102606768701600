import React from "react";
import { useParams } from "react-router-dom";
import Owners from "../components/owners/owners";
import CardContent from "@mui/material/CardContent";
import OwnerDetail from "../components/owners/ownerDetail";
import Sesion from "../components/commons/sesion";

export default function Merchants() {
  const { ownerId } = useParams();

  return (
    <div className={"Drawer"}>
      <CardContent>
        <Sesion screen={"Merchants"} />
        {ownerId !== undefined ? (
          <OwnerDetail client_id={ownerId} />
        ) : (
          <Owners />
        )}
      </CardContent>
    </div>
  );
}
