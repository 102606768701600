import React from "react";
import AggridTable from "../../aggrid/aggridTable";
import { ColumnsGenerator } from "../../operations/columnGenerator";
import { useTranslation } from "react-i18next";

const Cron = () => {
  const [t] = useTranslation("global");
  const toolTipValueGetter = () => t("AGgrid.Message.Edit");
  const columns = ColumnsGenerator([
    {
      headerName: t("Integrator.Cron.Id"),
      field: "id",
      minWidth: 140,
      editable: false,
    },
    {
      headerName: t("Integrator.Cron.Merchant_id"),
      field: "merchant_id",
      minWidth: 120,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Cron.Type"),
      field: "type",
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["EMAIL", "SFTP"],
      },
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Cron.Data"),
      field: "data",
      minWidth: 120,
      editable: true,
      cellEditor: "popupEditInfo",
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Cron.Cron"),
      field: "cron",
      minWidth: 120,
      editable: true,
      cellEditor: "popupEditCron",
      tooltipValueGetter: toolTipValueGetter,
    },
  ]);

  const getUrl = () => `mts/crontransaction`;

  return (
    <AggridTable
      columnDefs={columns}
      pagination={false}
      height={600}
      width={"100%"}
      showsearch={true}
      url={getUrl()}
      edit={true}
      startEditingcolumn={"merchant_id"}
    />
  );
};

export default Cron;
