import useAuth from "../../hooks/useAuth";

const PermissionsGate = ({ children, foundRedirect = null, scopes = [] }) => {
  const { auth } = useAuth();

  if (auth?.idTokenClaims) {
    return <>{children}</>;
  }

  return <>{children}</>;
};

export default PermissionsGate;
